<template>
  <div id="award-bidding-dashboard">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
        fixed
        style="position: sticky"
      >
        <v-toolbar-title>LO Detials</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
        </div>
        <v-container>
          <v-row wrap>
            <v-col align="center" cols="12" sm="12" md="12" lg="12" class="mb-0">
              <v-btn
                @click.prevent="refreshPageData"
                color="#a4c639"
                min-width="92"
                rounded
                small
                class="font-size-h6 px-3 py-3 white--text"
                >Refresh
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="!ResultFlag && !LoadingFlag">
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12">
              <h5 class="text-center text-danger">No records found.</h5>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="ComingSoonFlag && !LoadingFlag">
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12">
              <h5 class="text-center text-danger">Coming soon</h5>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="ResultFlag && !ComingSoonFlag">
          <v-row wrap>
            <v-col cols="12" sm="12" lg="6" md="12">
              <v-card>
                <v-card-title
                  class="text-center"
                  style="background-color: rgb(244, 236, 236); height: 50px"
                >
                  <h4 class="blue--text text--lighten">LO Location</h4>
                  <!-- <v-spacer></v-spacer> -->
                </v-card-title>
                <br />
                <v-card-text>
                  <v-form
                    ref="form1"
                    v-model="valid1"
                    lazy-validation
                    v-on:submit.prevent="confirmAlert"
                  >
                    <v-container>
                      <v-row wrap>
                        <v-col cols="12" sm="12" lg="6" md="6" class="p-0">
                          <h6 class="text-dark">State</h6>
                          <v-autocomplete
                            :reduce="(option) => option.value"
                            :loading="StateCodeOptionsLoading"
                            :items="StateCodeOptions"
                            :rules="StateCodeRules"
                            v-model="StateCode"
                            clearable
                            required
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" lg="6" md="6" class="p-0 pl-2">
                          <h6 class="text-dark">District</h6>
                          <v-autocomplete
                            :reduce="(option) => option.value"
                            :loading="DistrictCodeOptionsLoading"
                            :items="DistrictCodeOptions"
                            :rules="DistrictCodeRules"
                            v-model="DistrictCode"
                            clearable
                            required
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" lg="6" md="6" class="p-0">
                          <h6 class="text-dark">City</h6>
                          <v-autocomplete
                            :reduce="(option) => option.value"
                            :loading="CityCodeOptionsLoading"
                            :items="CityCodeOptions"
                            :rules="CityCodeRules"
                            v-model="CityCode"
                            clearable
                            required
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" lg="6" md="6" class="text-center">
                          <v-btn
                            :disabled="!valid1"
                            @click.prevent="confirmAlert"
                            :loading="SubmitFlag"
                            color="green"
                            min-width="80"
                            rounded
                            medium
                            class="font-size-h6 px-3 py-3 ml-6 mt-4 white--text"
                            >Save
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="12" lg="6" md="12">
              <v-card>
                <v-card-title
                  class="text-center"
                  style="background-color: rgb(244, 236, 236); height: 50px"
                >
                  <h4 class="blue--text text--lighten">Extension LO</h4>
                  <v-spacer></v-spacer>
                  <h6 class="text-dark">Total - {{ row.LomsCount }}</h6>
                </v-card-title>
                <br />
                <v-card-text>
                  <v-row wrap>
                    <v-col cols="12" lg="12" sm="12">
                      <v-row>
                        <v-col
                          cols="12"
                          lg="12"
                          md="12"
                          v-for="(item, index) in row.Loms"
                          :key="index"
                        >
                          <h6 class="preview-content">{{ index + 1 }}. {{ item }}</h6>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" lg="6" md="12">
              <v-card>
                <v-card-title
                  class="text-center"
                  style="background-color: rgb(244, 236, 236); height: 50px"
                >
                  <h4 class="blue--text text--lighten">
                    {{ tableOptions3.TableTitle }}
                  </h4>
                  <v-spacer></v-spacer>
                </v-card-title>
                <br />
                <v-card-text>
                  <h6 class="text-danger" v-if="tableData3.length == 0">
                    No records found.
                  </h6>
                  <v-row wrap v-if="tableData3.length > 0">
                    <v-col cols="12" md="12" align="center">
                      <v-data-table
                        class="elevation-1"
                        loading-text="Loading... Please wait"
                        v-model="selected3"
                        :search="search3"
                        :loading="tableLoadingFlag3"
                        :headers="tableColumns3"
                        :items="tableData3"
                        :show-select="tableOptions3.ShowSelectFlag"
                        :item-key="tableOptions3.ItemKey"
                        :single-select="tableOptions3.SingleSelectFlag"
                        :items-per-page="tableOptions3.ItemsPerPage"
                        :footer-props="tableOptions3.FooterProps"
                      >
                        <template v-slot:item.Growth="{ item }">
                          <v-chip :color="item.Color" draggable
                            ><b class="text-white">
                              {{ item.Growth }}
                            </b></v-chip
                          >
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" lg="6" md="12">
              <v-card>
                <v-card-title
                  class="text-center"
                  style="background-color: rgb(244, 236, 236); height: 50px"
                >
                  <h4 class="blue--text text--lighten">Foundation Contribution</h4>
                  <v-spacer></v-spacer>
                  <h6 class="text-dark">Total Amount - {{ row.FoundationAmount }}</h6>
                </v-card-title>
                <br />
                <v-card-text>
                  <h6 class="text-danger text-center" v-if="tableData1.length == 0">
                    No records found.
                  </h6>
                  <v-row wrap v-if="tableData1.length > 0">
                    <v-col cols="12" md="12" align="center">
                      <v-data-table
                        class="elevation-1"
                        loading-text="Loading... Please wait"
                        v-model="selected1"
                        :search="search1"
                        :loading="tableLoadingFlag1"
                        :headers="tableColumns1"
                        :items="tableData1"
                        :show-select="tableOptions1.ShowSelectFlag"
                        :item-key="tableOptions1.ItemKey"
                        :single-select="tableOptions1.SingleSelectFlag"
                        :items-per-page="tableOptions1.ItemsPerPage"
                        :footer-props="tableOptions1.FooterProps"
                      >
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="closePrompt"
          elevation="30"
          shaped
          tile
          medium
          color="#a52a2a"
          class="font-size-h6 px-6 mr-3 white--text"
        >
          Close
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    LomCode: {
      type: Number,
    },
  },
  data() {
    return {
      valid1: true,
      search: "",
      ProgessStart: 0,
      uploadPercentage: 0,

      alert: {},

      valid: true,

      SubmitFlag: false,
      RecordFlag: false,
      ResultFlag: false,
      LoadingFlag: false,
      ComingSoonFlag: false,

      records: {},
      row: {},

      StateCodeRules: [(v) => !!v || "State is required"],
      StateCode: "",
      StateCodeOptions: [],
      StateCodeOptionsLoading: false,

      DistrictCodeRules: [(v) => !!v || "District is required"],
      DistrictCode: "",
      DistrictCodeOptions: [],
      DistrictCodeOptionsLoading: false,

      CityCodeRules: [(v) => !!v || "City is required"],
      CityCode: "",
      CityCodeOptions: [],
      CityCodeOptionsLoading: false,

      tableOptions1: [],
      tableColumns1: [],
      tableData1: [],

      tableOptions3: [],
      tableColumns3: [],
      tableData3: [],

      tableLoadingFlag1: false,
      selected1: [],
      search1: "",

      tableLoadingFlag3: false,
      selected3: [],
      search3: "",

      RoleId: 0,
      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.CurrentYearId = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;
      }
    },
    records: function () {
      console.log("watch records function called");
      this.getStateCodeOptions();
      this.StateCode = this.records.StateId;
      this.DistrictCode = this.records.DistrictId;
      this.CityCode = this.records.CityId;
    },
    StateCodeOptions: function () {
      console.log("watch StateCodeOptions called");
      this.StateCodeOptionsLoading = false;
    },
    DistrictCodeOptions: function () {
      console.log("watch DistrictCodeOptions called");
      this.DistrictCodeOptionsLoading = false;
    },
    CityCodeOptions: function () {
      console.log("watch CityCodeOptions called");
      this.CityCodeOptionsLoading = false;
    },
    StateCode: function () {
      console.log("watch StateCode called");
      this.DistrictCodeOptions = [];
      this.CityCodeOptions = [];
      this.getDistrictCodeOptions();
    },
    DistrictCode: function () {
      console.log("watch DistrictCode called");
      this.CityCodeOptions = [];
      this.getCityCodeOptions();
    },
    LomCode: function () {
      console.log("LomCode called called");

      this.tableOptions1 = [];
      this.tableColumns1 = [];
      this.tableData1 = [];
      this.getTableRecords();
      this.getLomLocationDetails();
    },
  },
  created() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");

      this.pageData();
    },
    pageData() {
      console.log("pageData called");
      this.resetForm();
      this.getTableRecords();
      this.getLomLocationDetails();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideLomDetailsPrompt");
    },
    resetForm() {
      console.log("resetForm called");
      this.CityCode = "";
      this.CityCodeOptions = [];

      this.DistrictCode = "";
      this.DistrictCodeOptions = [];

      this.StateCode = "";
      this.StateCodeOptions = [];
    },
    getStateCodeOptions() {
      console.log("getStateCodeOptions called");
      this.StateCodeOptionsLoading = true;
      var selectbox1_source = "StateCode";
      var selectbox1_destination = "StateCodeOptions";
      var selectbox1_url = "api/states/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getDistrictCodeOptions() {
      console.log("getDistrictCodeOptions called");
      var StateCode = this.StateCode;
      console.log({ StateCode });
      if (StateCode != "") {
        this.DistrictCodeOptionsLoading = true;
        var selectbox1_source = "DistrictCode";
        var selectbox1_destination = "DistrictCodeOptions";
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityCodeOptions() {
      console.log("getCityCodeOptions called");

      var DistrictCode = this.DistrictCode;
      console.log({ DistrictCode });

      if (DistrictCode != "") {
        this.CityCodeOptionsLoading = true;
        var selectbox1_source = "CityCode";
        var selectbox1_destination = "CityCodeOptions";
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var message = "";

      var validate1 = true;
      console.log({ validate1 });

      if (validate1) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom/year-data";
        var role = this.RoleId;
        if (role == 2) {
          // For vaagai only

          var upload = {
            UserInterface: 3,
            Lom: this.LomCode,
          };
        } else {
          var upload = {
            UserInterface: 3,
          };
        }
        console.log(add_url);
        console.log({ upload });

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.row = {};
        this.ResultFlag = false;
        this.LoadingFlag = true;
        this.ComingSoonFlag = false;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log({ flag });
            console.log({ output });
            console.log({ records });

            thisIns.ResultFlag = flag;
            thisIns.ComingSoonFlag = records.ComingSoonFlag;

            if (flag == 1) {
              thisIns.row = records;

              thisIns.tableOptions1 = records.TableOptions1;
              thisIns.tableColumns1 = records.TableHeader1;
              thisIns.tableData1 = records.TableData1;

              // thisIns.tableOptions2 = records.TableOptions2;
              // thisIns.tableColumns2 = records.TableHeader2;
              // thisIns.tableData2 = records.TableData2;

              thisIns.tableOptions3 = records.TableOptions3;
              thisIns.tableColumns3 = records.TableHeader3;
              thisIns.tableData3 = records.TableData3;
            } else {
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.LoadingFlag = false;
            thisIns.tableLoadingFlag1 = false;
            thisIns.tableLoadingFlag3 = false;
          });
      } else {
        message = "LOM details not found. ";
        console.log("error=" + message);
      }
    },
    getLomLocationDetails() {
      console.log("getLomLocationDetails called");

      var validate1 = true;
      console.log({ validate1 });

      if (validate1) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom/show";
        if (this.RoleId == 2) {
          var upload = {
            UserInterface: 1,
            Lom: this.LomCode,
          };
        } else {
          var upload = {
            UserInterface: 2,
          };
        }
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
        );

        this.RecordFlag = false;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.LoadingFlag = true;
        this.records = {};

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.RecordFlag = flag;

            if (flag == 1) {
              console.log("records=" + JSON.stringify(records));
              if (records.StateId != "") {
                thisIns.records = records;
              }
              // if (records.StateId != "") {
              //   thisIns.StateCode = records.StateId;
              //   thisIns.DistrictCode = records.DistrictId;
              //   thisIns.CityCode = records.CityId;
              // }
              // thisIns.toast("success", output);
            } else {
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Record not found.";
        console.log("error=" + message);
      }
    },
    confirmAlert() {
      // var validate1 = this.$refs.form1.validate();
      // console.log("validate1=" + validate1);

      console.log("state = ", this.StateCode);
      console.log("district = ", this.DistrictCode);
      console.log("city = ", this.CityCode);

      if (this.StateCode > 0 && this.DistrictCode > 0 && this.CityCode > 0) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        this.sweetAlert("error", "Kindly fill the required fields", true);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      // this.SubmitFlag = true;
      this.ResultFlag = false;

      var upload = {
        UserInterface: 2,
        City: this.CityCode,
      };
      console.log("upload=" + JSON.stringify(upload));

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      var add_url = server_url + "api/lom/update";
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      // this.SubmitFlag = true;

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.SubmitFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);
          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            thisIns.sweetAlert("success", output, true);
            // thisIns.ResultFlag = true;
            thisIns.pageData();
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          // thisIns.SubmitFlag = false;
        });
    },
  },
  beforeMount() {
    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? "" : RoleId;
    console.log({ RoleId });
    this.RoleId = RoleId;

    this.refreshPageData();
  },
};
</script>

<style lang="scss">
#award-bidding-dashboard {
  // #menu-list {
  //   height: 100px;
  // }
}
</style>
